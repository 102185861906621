<template>
  <div class="wrapper">
    <div class="content">
      <div class="container">
        <h1>CUMPLIMIENTO DE LA LEY GENERAL DE DISCAPACIDAD (LGD) CON MEDIDAS ALTERNATIVAS</h1>
        <img src="@/assets/images/logo_nosotros.svg" alt="" />
        <h2>¿Qué es la Ley General de Discapacidad??</h2>
        <p>
        Las empresas públicas y privadas que empleen a un número de <strong> 50 
        o más trabajadores </strong> están obligadas a que, de entre ellos, <strong> 
        al menos el 2% sean trabajadores con discapacidad </strong>.
        </p>
        <p>
          Para calcular cuántos empleados con discapacidad se debe de incorporar, habrá que tomar como referencia los 12 meses inmediatamente anteriores y realizar un promedio de los trabajadores en plantilla. El 2% del volumen calculado será el número de empleados discapacitados que hace falta tener en plantilla. Si salen decimales, puedes redondear a la baja. 
        </p>

        <h2 style="margin-top:60px;">¿Cómo cumplir con la Ley General de Discapacidad?</h2>

        <p>
            La mejor forma es cumpliendo con la cuota del 2% de trabajadores con discapacidad en la plantilla de la empresa, mediante contratación directa. Si esto no fuese posible, se puede cumplir con Medidas Alternativas, pero para ello deben solicitar previamente un documento llamado <strong>Solicitud de Declaración de Excepcionalidad</strong> y de adopción de Medidas Alternativas ante los Servicios Públicos de Empleo. Una vez aprobada la solicitud, se concede el <strong>Certificado de Excepcionalidad</strong>, un documento imprescindible para cumplir con la LGD mediante las siguientes medidas alternativas: 
        </p>
        <ol>
            <li>Contratar los servicios de algún Centro Especial de Empleo, (una empresa de empleo protegido que fomenta la integración laboral de personas con discapacidad). 
                <strong>Desde Solutz te ayudamos a cumplir con este requisito, a través de los recibos de luz. Contacta con nosotros para cumplir con esta norma y además podrás reducir el coste de tu factura y consumir energía 100% verde para cumplir con tus políticas de responsabilidad social y medioambiental.</strong>
            </li>
            <div style="margin: 60px 0">
                <router-link to="/contacto"><button>Contactar</button></router-link>
            </div>
            <li>
                Hacer donaciones o patrocinar a alguna entidad sin ánimo de lucro, cuya labor consista en formar o insertar personas con discapacidad en el mundo laboral. Esta opción permite que las empresas disfruten de importantes beneficios fiscales: serán deducibles al 35% o incluso al 40% si se trata de donaciones periódicas realizadas durante al menos tres años a la misma entidad, por un importe igual o superior
            </li>

        </ol>
        <h2 style="margin-top:60px;">Consecuencias del incumplimiento de la LGD</h2>
            <p>
              No cumplir con la Ley General de Discapacidad conlleva <strong>sanciones económicas</strong> cuyo importe se sitúa entre los 301 y los 6.000 euros para las infracciones más leves y entre 600.000 y 1.000.000 de euros para las más graves. 
                Pero no sólo eso, las sanciones también imposibilitan que una empresa pueda trabajar para la Administración Pública, conllevan la pérdida de las bonificaciones laborales o hacen que la empresa no pueda acceder a ayudas de la Administración como <strong>no poder presentarse a concursos públicos</strong>.  
            </p>
            <p>Dada la situación actual en la consigna de los fondos europeos, 
                creemos necesario que pueda tener la posibilidad de aplicar a estas ayudas para el crecimiento de su empresa.
            </p>

        <h2 style="margin-top:60px;">¿Cómo te ayudamos a cumplir con la LGD?</h2>
            <ol>
                <li>
                    ¡Contacta con nosotros! Escribe tu información de contacto (no te llevará más de un minuto) y te llamaremos en menos de 72 horas para resolver cualquier duda que te haya surgido.
                </li>
                <div style="margin: 60px 0">
                    <router-link to="/contacto"><button>Contactar</button></router-link>
                </div>
                <li>Conocernos un poco mejor, saber vuestros hábitos y previsiones de consumo de energía <strong>logrará el máximo ahorro posible en la factura de la luz</strong>.
                </li>
                <li>Envíanos la factura de la luz del último mes para comparar tu situación actual con el escenario ideal gracias a nuestro programa de optimización de compra.
                </li>
                <li>Te envíamos la propuesta de ahorro energético, y de haber conformidad, se comienza una colaboración.
                </li>
                <li>En una semana habremos tramitado el cambio <strong>sin que tengas que hacer nada.</strong></li>
                <li>Una vez hecho el cambio, desde Solutz te ayudaremos a <strong>obtener el Certificado de Excepcionalidad</strong>, último requisito para cumplir con la LGD.</li>
            </ol>

        <div style="margin: 60px 0">
          <router-link to="/contacto"><button>Contactar</button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wrapper {
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 200px;
  background-image: url(../assets/images/wavy_background_bottom_lay.svg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .content {
    .container {
      width: calc(100% - 80px);
      padding: 15px 40px;
      background-color: $dirty-white;
      h1,
      h2,
      p {
        text-align: left;
      }
      img {
        height: 200px;
      }
    }
  }
}
</style>
